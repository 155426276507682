// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { setAuthToken, removeToken } from './store/slices/app/controlers/auth'
import { refreshHeader, refreshCompanies } from './store/slices/app/controlers/updater.js'

import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import { ToastContainer } from 'react-toastify'

import Login from './app/pages/Auth/Auth'
import HomeMobile from './app/pages/HomeMobile.jsx'
import HomeToolpad from './app/pages/HomeToolpad.jsx'

import Updater from './app/pages/Updater'
import SocketConnector from './services/app/client-server/socket-connector.service.tsx'
import ProtectedRoute from './routes/ProtectedRoute'

import { LicenseInfo } from '@mui/x-data-grid-pro'
import HistoryListener from './services/app/processes/history-listener.service.js'
import useWindowDimensions from './app/hooks/useWindowDimensions.js'

import Cookies from 'js-cookie'
import './index.scss'
import './index.layout.scss'
import './index.mobile.scss'

const rollbarConfig = {
  accessToken: '4d995080d4804cd9bde4aa5e81f10dca',
  environment: process.env.REACT_APP_API_URL,
}

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { width } = useWindowDimensions()

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

  const [firstUpdate, setFirstUpdate] = useState(true)
  const reduxToken = useSelector(state => state.authReducer.authData.token)

  useEffect(() => {
    const TOKEN = Cookies.get('accessToken')

    TOKEN && dispatch(setAuthToken(TOKEN))
    !!!TOKEN && dispatch(removeToken())

    setFirstUpdate(false)

    // ----------------------------------------------------------------
    // navigate('/metriva/protocol')
    // ----------------------------------------------------------------
  }, [])

  useEffect(() => {
    reduxToken && dispatch(refreshHeader())
    reduxToken && dispatch(refreshCompanies())
  }, [reduxToken])

  useEffect(() => {
    const checkCookie = () => {
      const TOKEN = Cookies.get('accessToken')
      if (!TOKEN && reduxToken) {
        dispatch(removeToken())
        navigate('/')
      }
    }

    const intervalId = setInterval(checkCookie, 1000 * 60)

    return () => clearInterval(intervalId)
  }, [reduxToken, dispatch, navigate])

  useEffect(() => {
    ;(async () => {
      if (!('Notification' in window)) {
        alert('Этот браузер не поддерживает уведомления')
        return
      }

      const permission = await Notification.requestPermission()

      if (permission === 'granted') {
        new Notification('Спасибо! Теперь вы будете получать уведомления.')
      } else {
        alert('Вы отключили уведомления. Их можно включить в настройках браузера.')
      }
    })()
  }, [])

  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary>
        <React.Fragment>
          {/* <SocketConnector /> */}
          <HistoryListener />
          <div className='App'>
            <ToastContainer
              toastStyle={{
                fontSize: '14px',
                lineHeight: '20px',
                fontFamily: "'Wix Madefor Display', sans-serif",
              }}
            />
            <Updater />
            <Routes>
              <Route
                path='/'
                element={
                  !!!reduxToken ? <Login /> : <Navigate replace={true} to={'/metriva/protocol'} />
                }
              />
              <Route
                path='/metriva/*'
                element={
                  <ProtectedRoute>
                    {width <= 1050 && <HomeMobile />}
                    {width > 1050 && <HomeToolpad />}
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </React.Fragment>
      </RollbarErrorBoundary>
    </RollbarProvider>
  )
}

export default App
