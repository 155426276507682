import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types.models.views.ts'

const { Container, Mobile } = css

const ProtocolTransfer: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container style={{ flexFlow: 'row' }}>
        <span style={{ fontWeight: 700 }}>Статус протокола</span>
        <span
          style={{
            backgroundColor: 'rgba(255, 234, 214, 1)',
            borderRadius: '10px',
            padding: '4px 10px 5px',
          }}
        >
          Передается в ФСА
        </span>
      </Container>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start', gap: '10px' }}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z'
                fill='#CFECFF'
              />
              <path
                d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L12.7348 11.2045L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                fill='#0378CC'
              />
            </svg>
            <span>Создан поверителем</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ color: '#858585' }}>
              {`${viewData.created_by.name} ${viewData.created_by.patr} ${viewData.created_by.surname}`}
              <br />
              {`${viewData.created_at}`}
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start', gap: '10px' }}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z'
                fill='#CFECFF'
              />
              <path
                d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L12.7348 11.2045L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                fill='#0378CC'
              />
            </svg>
            <span>Одобрен к передаче</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>№ свидетельства или извещения</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Публикация данных о поверке СИ</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start', gap: '10px' }}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z'
                fill='#CFECFF'
              />
              <path
                d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L12.7348 11.2045L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                fill='#0378CC'
              />
            </svg>
            <span>Опубликован в Аршин</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ color: '#858585' }}>{viewData.status_arshin}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start', gap: '10px' }}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z'
                fill='#CFECFF'
              />
              <path
                d='M16.0303 10.0303C16.3232 9.73744 16.3232 9.26256 16.0303 8.96967C15.7374 8.67678 15.2626 8.67678 14.9697 8.96967L12.7348 11.2045L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.0303 10.0303Z'
                fill='#0378CC'
              />
            </svg>
            <span>Опубликован в ФСА</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ color: '#858585' }}>{viewData.status_fsa}</span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default ProtocolTransfer
